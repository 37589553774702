import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiButton, EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Motion_Detection/Alarm_FTP_Upload/NavButtons';
import ForumBox from 'components/Motion_Detection/Alarm_FTP_Upload/ForumBox';
import ConfigTable from 'components/Motion_Detection/Alarm_FTP_Upload/VGA_Series/ConfigTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Alarm FTP Upload - VGA Series Cameras",
  "path": "/Motion_Detection/Alarm_FTP_Upload/VGA_Series/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "Set the FTP upload during alarm and photo series",
  "image": "./MD_SearchThumb_FTPSettingsAlarmUpload_VGA.png",
  "social": "/images/Search/MD_SearchThumb_FTPSettingsAlarmUpload_VGA.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_MD-FTP-Upload_white.webp",
  "chapter": "Motion Detection"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Alarm FTP Upload - VGA Series Cameras' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='Set the FTP upload during alarm and photo series' image='/images/Search/MD_SearchThumb_FTPSettingsAlarmUpload_VGA.png' twitter='/images/Search/MD_SearchThumb_FTPSettingsAlarmUpload_VGA.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Bewegungserkennung/Alarm_FTP_Upload/VGA_Serie/' locationFR='/fr/Motion_Detection/Alarm_FTP_Upload/VGA_Series/' crumbLabel="FTP Upload" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "alarm-ftp-upload",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#alarm-ftp-upload",
        "aria-label": "alarm ftp upload permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm FTP Upload`}</h2>
    <h3 {...{
      "id": "vga-kameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#vga-kameras",
        "aria-label": "vga kameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`VGA Kameras`}</h3>
    <p>{`FTP Upload and FTP Alarm notification settings`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a9d31e4e61ffd01fa9cf8b40f852a8df/0a47e/QNAP-FTP_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.69565217391304%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABy0lEQVQoz1XSXY+qMBAGYP7/H/HKuKs5q3wpiOtKW0otX2oXBFoMEI1m9Vx45Qm6evRJb6fvTGYk0zRlWTYMA2OcpinnPHuW54yxxWIRJ431kyRJpLf3t1arZU0mGDtRGAZBkGWZuOG82BSZ4GVZnc/nw+Gwf7LdbqVOp9Nut23bRgiNdH00HDoYO1cIIYxdIYqqrquyzvM8TdfpXZPc7XZ7vR6EECFkjceaotq27d3N5yQMgp+f4/F4KstSCLEpNjdCCOn9Cl1zTMPQFFWRFdsGhBDXdQkhnudlWS54wTnPn6Rp2rQtD2QIIQBgYk10TQfXSt/3KaV0Pl9GyyROkjjhXHDOm/BH8mKxFELs93vGGADgd1gIgQ2w41BKPUp93ycucbGLkeNRj12tViup4OJ0+nu5XIpCDPWhruqmYRij5hFCKKWr5fL71f/iOIlFUex2O8a+VVn9+PNhjS179jX9nAIAPM/jnBd3mydN2xCi28AAAF3TB/2BMpD7/YGqaNPPqeu6gv9u/XYYD3EcSwihx1Z1TVNlxRpbY9Ocfc1834/CqCzLqqrqut68apLRHYQwCIIojOI4vn3MGEM2CP3Q9zyMcPpqvV7/A27/WQaNX7mlAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a9d31e4e61ffd01fa9cf8b40f852a8df/e4706/QNAP-FTP_02.avif 230w", "/en/static/a9d31e4e61ffd01fa9cf8b40f852a8df/d1af7/QNAP-FTP_02.avif 460w", "/en/static/a9d31e4e61ffd01fa9cf8b40f852a8df/56a33/QNAP-FTP_02.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a9d31e4e61ffd01fa9cf8b40f852a8df/a0b58/QNAP-FTP_02.webp 230w", "/en/static/a9d31e4e61ffd01fa9cf8b40f852a8df/bc10c/QNAP-FTP_02.webp 460w", "/en/static/a9d31e4e61ffd01fa9cf8b40f852a8df/e88ff/QNAP-FTP_02.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a9d31e4e61ffd01fa9cf8b40f852a8df/81c8e/QNAP-FTP_02.png 230w", "/en/static/a9d31e4e61ffd01fa9cf8b40f852a8df/08a84/QNAP-FTP_02.png 460w", "/en/static/a9d31e4e61ffd01fa9cf8b40f852a8df/0a47e/QNAP-FTP_02.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a9d31e4e61ffd01fa9cf8b40f852a8df/0a47e/QNAP-FTP_02.png",
              "alt": "Alarm FTP Upload Setup for the VGA Camera Series",
              "title": "Alarm FTP Upload Setup for the VGA Camera Series",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`After setting your FTP configuration, continue with our `}<a parentName="p" {...{
        "href": "/en/Motion_Detection/Setup/"
      }}>{`Motion Detection Setup Guide`}</a>{` to activate the FTP upload in case of an alarm event.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ConfigTable mdxType="ConfigTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0fc06b9980c03b716832fa6f514365e1/29d31/Email_Einstellungen_MJPEG_02_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMBBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHoXTSrC//EABsQAAIBBQAAAAAAAAAAAAAAAAABEBESISIx/9oACAEBAAEFAlqKOlmaH//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABcQAQADAAAAAAAAAAAAAAAAADEBECD/2gAIAQEABj8CHDNf/8QAGxABAAICAwAAAAAAAAAAAAAAAQARIVEQQcH/2gAIAQEAAT8hILAKziUS6mNQ26iX08P/2gAMAwEAAgADAAAAEEzP/8QAFhEAAwAAAAAAAAAAAAAAAAAAARBh/9oACAEDAQE/EDF//8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8QJ//EABoQAQADAQEBAAAAAAAAAAAAAAEAESFRkTH/2gAIAQEAAT8Qtjg9Y7QeROXkKtfGZG6kreVIns//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0fc06b9980c03b716832fa6f514365e1/e4706/Email_Einstellungen_MJPEG_02_EN.avif 230w", "/en/static/0fc06b9980c03b716832fa6f514365e1/d1af7/Email_Einstellungen_MJPEG_02_EN.avif 460w", "/en/static/0fc06b9980c03b716832fa6f514365e1/70e80/Email_Einstellungen_MJPEG_02_EN.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0fc06b9980c03b716832fa6f514365e1/a0b58/Email_Einstellungen_MJPEG_02_EN.webp 230w", "/en/static/0fc06b9980c03b716832fa6f514365e1/bc10c/Email_Einstellungen_MJPEG_02_EN.webp 460w", "/en/static/0fc06b9980c03b716832fa6f514365e1/426ac/Email_Einstellungen_MJPEG_02_EN.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0fc06b9980c03b716832fa6f514365e1/e83b4/Email_Einstellungen_MJPEG_02_EN.jpg 230w", "/en/static/0fc06b9980c03b716832fa6f514365e1/e41a8/Email_Einstellungen_MJPEG_02_EN.jpg 460w", "/en/static/0fc06b9980c03b716832fa6f514365e1/29d31/Email_Einstellungen_MJPEG_02_EN.jpg 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0fc06b9980c03b716832fa6f514365e1/29d31/Email_Einstellungen_MJPEG_02_EN.jpg",
              "alt": "Alarm FTP Upload Setup for the VGA Camera Series",
              "title": "Alarm FTP Upload Setup for the VGA Camera Series",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "step-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-2",
        "aria-label": "step 2 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 2`}</h3>
    <p><strong parentName="p">{`FTP Server`}</strong>{`: If you want to use the FTP upload in case of an alarm you have to set this by clicking Others / Alarm Service Settings. If an alarm occurs the event will last for 60 seconds. With the time interval you can set how many pictures you want to receive during one event. So if you set the time interval to 10 you would receive 6 pictures during every event.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      